<template>
  <div class="container-fluid mt-4">
    <div class="row">
      <div class="col-12 position-relative p-0 m-0">
        <div style="-webkit-scrollbar-width: 0px; width:100%;   height: calc(100vh - 150px);  left:0; top:0;border:none" ref="game_wrapper" id='game_wrapper'>
          <iframe ref="frame"></iframe>
        </div>
        <div v-if="isShow" class="background-image" :style="{backgroundImage: `url(${currentGame.url})`}"/>
        <div v-if="isShow" class="content">
          {{ $t('games.play.info', { currency: basicCurrency }) }} <br />
          <button :disabled="load" @click="playGame" style="margin-top: 30px" type="submit" class="button-submit p-button p-component">{{ $t('games.play.start') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Game',
  data () {
    return {
      isShow: true,
      load: false
    }
  },
  watch: {
    currentAccount () {
      this.playGame()
    }
  },
  computed: {
    ...mapGetters('game', ['currentGame']),
    ...mapGetters('user', ['currentAccount', 'basicCurrency'])
  },
  methods: {
    ...mapActions('game', ['openGame']),
    playGame () {
      this.load = true
      this.openGame({ gameId: this.$route.params.gameId, currency: this.currentAccount.currency.id })
        .then(data => {
          this.isShow = false
          if (this.detectMobile() && !this.checkSafari()) {
            window.open(data.result.url, '_blank')
            this.$router.go(-1)
          } else {
            this.$refs.frame.src = data.result.url
          }
        })
        .catch(e => {
          this.load = false
          console.error(e)
          this.$noty.error(e.response.data.message)
        })
    },
    detectMobile () {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
      ]

      return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem)
      })
    },
    checkSafari () {
      return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') === -1 &&
        navigator.userAgent.indexOf('FxiOS') === -1
    }
  },
  mounted () {
    console.log(this.currentGame)
  }
}
</script>
<style>
iframe {
  width: 100% !important;
  height: 100% !important;
}
</style>
<style lang="scss" scoped>
.background-image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  display: block;
  width: 100%;
  height: 100%;
  filter: blur(20px);
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: center;
}

.content {
  position: absolute;
  display: block;
  text-align: center;
  left: 0;
  top: 25%;
  max-width: 488px;
  right: 0;
  width: 100%;
  margin: auto;
  z-index: 3;
  color: white;
}

//.game-preview {
//  display: block;
//  position: absolute;
//  left: 0;
//  top: 0;
//  width:100%;
//  height: calc(100vh - 100px);
//  color: #fff;
//  text-align: center;
//  background-repeat: no-repeat;
//  background-size: 100%;
//  //background: rgba(0, 0, 0, 0.49);
//  padding-top: 200px;
//  margin-top: -25px;
//  width: 100% !important;
//  height: 100% !important;
//  filter: blur(24px);
//  z-index: 1;
//}
//
//.game-preview-text {
//  z-index: 100;
//  filter: none;
//}

</style>
